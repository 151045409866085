import React from 'react';
import { getCurrentLangKey } from 'ptz-i18n';
import { navigate } from 'gatsby';
import messagesEN from './src/data/messages/en';
import messagesRU from './src/data/messages/ru';
import messagesES from './src/data/messages/es';
import 'intl';
import { IntlProvider } from 'react-intl';
import Languages from './src/languages';
import 'intl/locale-data/jsonp/ru';
import 'intl/locale-data/jsonp/es';
import 'intl/locale-data/jsonp/en';
import Cookie from './src/components/Cookie';
import LeaveReview from './src/components/LeaveReview';

// https://www.science.co.il/language/Locale-codes.php
const LANGUAGES_MAP = {
  uk: 'ru',
  be: 'ru',
  ru: 'ru',
  ca: 'es',
  es: 'es',
  hi: 'en',
  hu: 'en',
  lt: 'en',
  lv: 'en',
  pl: 'en',
  sr: 'en',
  sk: 'en',
  sl: 'en',
  so: 'en',
  pt: 'en',
  sv: 'en',
  th: 'en',
  tr: 'en',
  tk: 'en',
  uz: 'en',
  vi: 'en',
  la: 'en',
  en: 'en',
  de: 'en',
};

export function wrapPageElement({ element, props }) {
  const url = props.path;
  const { langs, defaultLangKey } = Languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);

  const messages = {
    es: messagesES,
    en: messagesEN,
    ru: messagesRU,
  }[langKey];

  return (
    <IntlProvider locale={langKey} messages={messages}>
      <div />
      <Cookie />
      <LeaveReview />

      {element}
    </IntlProvider>
  );
}

export function onRouteUpdate({ location }) {
  const STORAGE_KEY = 'user-language';

  const userLocaleRaw = navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

  if (!userLocaleRaw) {
    return;
  }

  const { langs, defaultLangKey } = Languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, location.pathname);

  const userLocale = userLocaleRaw.substring(0, 2);

  let redirectLang = window.localStorage.getItem(STORAGE_KEY);
  if (!redirectLang) {
    redirectLang = LANGUAGES_MAP[userLocale] || defaultLangKey;
  }

  if (langKey !== redirectLang) {
    let path = location.pathname;
    path = langKey === defaultLangKey ? path : path.substring(3);
    path = redirectLang === defaultLangKey ? path : `/${redirectLang}${path}`;
    navigate(path);
    window.localStorage.setItem(STORAGE_KEY, redirectLang);
  }
}

export function onClientEntry() {
if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
