import React from 'react';
import Modal from 'react-modal';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import * as GlobalCSS from '../styles.module.css';
import * as CSS from './LeaveReview.module.css';
import LogoReviewsPNG from '../images/logo-reviews.png';
import Button from './Button';

// function LeaveReviewBig({ leaveReviewIsOpen, setLeaveReviewIsOpen }) {
//   const [formIsOpen, setFormIsOpen] = React.useState(false);
//   const [finishIsOpen, setFinishIsOpen] = React.useState(false);
//
//   function closeLeaveReview() {
//     setLeaveReviewIsOpen(false);
//   }
//
//   function openLeaveReviewForm() {
//     setLeaveReviewIsOpen(false);
//     setFormIsOpen(true);
//   }
//
//   function openFinish() {
//     setFormIsOpen(false);
//     setFinishIsOpen(true);
//   }
//
//   function closeLeaveForm() {
//     setFormIsOpen(false);
//   }
//
//   function closeFinish() {
//     setFinishIsOpen(false);
//   }
//
//   /* eslint max-len: 0 */
//   return (
//     <>
//       <Modal
//         ariaHideApp={false}
//         isOpen={leaveReviewIsOpen}
//         onRequestClose={closeLeaveReview}
//         className={classnames(GlobalCSS.modal, CSS.modalReviewWelcomeBg)}
//         overlayClassName={classnames(GlobalCSS.overlay, CSS.modalReviewOverlay)}
//       >
//         <div className={GlobalCSS.modalLogo}>
//           <span>
//             HOOKAH
//             <br />
//             VALENCIA
//           </span>
//         </div>
//
//         <div className={GlobalCSS.modalClose}>
//           <span onClick={closeLeaveReview}>&#x2715;</span>
//         </div>
//
//         <div className={CSS.reviewInfoBlock}>
//           <div className={CSS.reviewLogo}>
//             <img src={LogoReviewsPNG} alt="Hookah Base" />
//           </div>
//
//           <div className={CSS.reviewTitle}>
//             Уже успели постели нас посетить?
//           </div>
//
//           <div className={CSS.reviewDetails}>
//             Мы будем очень благодарны, если вы поделитесь впечатления и оставите отзыв
//           </div>
//
//           <div className={CSS.reviewLeaveViewButton}>
//             <Button to="/#" onClick={openLeaveReviewForm}>Оставить отзыв</Button>
//           </div>
//         </div>
//       </Modal>
//
//       <Modal
//         ariaHideApp={false}
//         isOpen={formIsOpen}
//         onRequestClose={closeLeaveForm}
//         className={classnames(GlobalCSS.modal, CSS.modalReviewFormBg)}
//         overlayClassName={classnames(GlobalCSS.overlay)}
//       >
//         <div className={GlobalCSS.modalLogo}>
//           <img src={LogoReviewsPNG} alt="Hookah Base" />
//           <span>
//             HOOKAH
//             <br />
//             VALENCIA
//           </span>
//         </div>
//
//         <div className={GlobalCSS.modalClose}>
//           <span onClick={closeLeaveForm}>&#x2715;</span>
//         </div>
//
//         <div className={CSS.reviewInfoBlock}>
//           <div className={CSS.reviewTitle}>
//             Уже успели постели нас посетить?
//           </div>
//
//           <div className={CSS.reviewDetails}>
//             Мы будем очень благодарны, если вы поделитесь впечатления и оставите отзыв
//           </div>
//
//           <div>
//             <label htmlFor="review-name" className={CSS.label}>Имя</label>
//             <input type="text" id="review-name" className={CSS.input} />
//           </div>
//
//           <div>
//             <label htmlFor="review-text" className={CSS.label}>Ваш отзыв</label>
//             <textarea id="review-text" className={CSS.input} rows="4" />
//           </div>
//
//           <div className={CSS.reviewStars}>
//             <label className={CSS.label}>Ваша оценка</label>
//
//             <div>
//               <svg
//                 width="18"
//                 height="18"
//                 viewBox="0 0 18 18"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   fill="#F3BA0B"
//                 />
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   fill="url(#paint0_linear_54_1354)"
//                   fillOpacity="0.48"
//                 />
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   stroke="#EEC43F"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//                 <defs>
//                   <linearGradient
//                     id="paint0_linear_54_1354"
//                     x1="1.36623"
//                     y1="-11.7578"
//                     x2="17.6924"
//                     y2="-9.69296"
//                     gradientUnits="userSpaceOnUse"
//                   >
//                     <stop stopColor="#E7CA6C" />
//                     <stop offset="0.533323" stopColor="#F5DD87" />
//                     <stop offset="1" stopColor="#C7A54B" />
//                   </linearGradient>
//                 </defs>
//               </svg>
//               <svg
//                 width="18"
//                 height="18"
//                 viewBox="0 0 18 18"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   fill="#F3BA0B"
//                 />
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   fill="url(#paint0_linear_54_1354)"
//                   fillOpacity="0.48"
//                 />
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   stroke="#EEC43F"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//                 <defs>
//                   <linearGradient
//                     id="paint0_linear_54_1354"
//                     x1="1.36623"
//                     y1="-11.7578"
//                     x2="17.6924"
//                     y2="-9.69296"
//                     gradientUnits="userSpaceOnUse"
//                   >
//                     <stop stopColor="#E7CA6C" />
//                     <stop offset="0.533323" stopColor="#F5DD87" />
//                     <stop offset="1" stopColor="#C7A54B" />
//                   </linearGradient>
//                 </defs>
//               </svg>
//               <svg
//                 width="18"
//                 height="18"
//                 viewBox="0 0 18 18"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   fill="#F3BA0B"
//                 />
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   fill="url(#paint0_linear_54_1354)"
//                   fillOpacity="0.48"
//                 />
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   stroke="#EEC43F"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//                 <defs>
//                   <linearGradient
//                     id="paint0_linear_54_1354"
//                     x1="1.36623"
//                     y1="-11.7578"
//                     x2="17.6924"
//                     y2="-9.69296"
//                     gradientUnits="userSpaceOnUse"
//                   >
//                     <stop stopColor="#E7CA6C" />
//                     <stop offset="0.533323" stopColor="#F5DD87" />
//                     <stop offset="1" stopColor="#C7A54B" />
//                   </linearGradient>
//                 </defs>
//               </svg>
//               <svg
//                 width="18"
//                 height="18"
//                 viewBox="0 0 18 18"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   fill="#F3BA0B"
//                 />
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   fill="url(#paint0_linear_54_1354)"
//                   fillOpacity="0.48"
//                 />
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   stroke="#EEC43F"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//                 <defs>
//                   <linearGradient
//                     id="paint0_linear_54_1354"
//                     x1="1.36623"
//                     y1="-11.7578"
//                     x2="17.6924"
//                     y2="-9.69296"
//                     gradientUnits="userSpaceOnUse"
//                   >
//                     <stop stopColor="#E7CA6C" />
//                     <stop offset="0.533323" stopColor="#F5DD87" />
//                     <stop offset="1" stopColor="#C7A54B" />
//                   </linearGradient>
//                 </defs>
//               </svg>
//
//               <svg
//                 width="18"
//                 height="18"
//                 viewBox="0 0 18 18"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M8.22834 1.19413C8.495 0.490793 9.50417 0.490793 9.77167 1.19413L11.4967 5.97246C11.5568 6.12811 11.6628 6.26186 11.8006 6.35604C11.9384 6.45022 12.1015 6.5004 12.2683 6.49996H16.5075C17.2908 6.49996 17.6325 7.47496 17.0167 7.95246L14 10.6666C13.8649 10.7705 13.7661 10.9145 13.7179 11.078C13.6697 11.2415 13.6745 11.416 13.7317 11.5766L14.8333 16.2458C15.1017 16.9958 14.2333 17.64 13.5767 17.1783L9.47917 14.5783C9.33885 14.4797 9.17151 14.4268 9 14.4268C8.82849 14.4268 8.66116 14.4797 8.52084 14.5783L4.42334 17.1783C3.7675 17.64 2.89834 16.995 3.16667 16.2458L4.26834 11.5766C4.32548 11.416 4.3303 11.2415 4.28209 11.078C4.23389 10.9145 4.13513 10.7705 4 10.6666L0.983336 7.95246C0.366669 7.47496 0.710003 6.49996 1.49167 6.49996H5.73084C5.89775 6.50051 6.06089 6.45038 6.19868 6.35619C6.33648 6.26199 6.44242 6.12818 6.5025 5.97246L8.2275 1.19413H8.22834Z"
//                   fill="#F4F2EA"
//                   fillOpacity="0.1"
//                   stroke="#EEC43F"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//               </svg>
//             </div>
//           </div>
//
//           <div className={CSS.reviewLeaveViewButton}>
//             <Button to="/#" type="ghost" onClick={openFinish}>Оставить отзыв</Button>
//           </div>
//         </div>
//       </Modal>
//
//       <Modal
//         ariaHideApp={false}
//         isOpen={finishIsOpen}
//         onRequestClose={closeFinish}
//         className={classnames(GlobalCSS.modal, CSS.modalReviewFinishBg)}
//         overlayClassName={classnames(GlobalCSS.overlay)}
//       >
//         <div className={GlobalCSS.modalLogo}>
//           <img src={LogoReviewsPNG} alt="Hookah Base" />
//           <span>
//             HOOKAH
//             <br />
//             VALENCIA
//           </span>
//         </div>
//
//         <div className={GlobalCSS.modalClose}>
//           <span onClick={closeFinish}>&#x2715;</span>
//         </div>
//
//         <div className={classnames(CSS.reviewInfoBlock, CSS.reviewInfoSuccess)}>
//           <h2 className={CSS.modalFinishTitle}>Спасибо!</h2>
//         </div>
//       </Modal>
//     </>
//   );
// }

function LeaveReview() {
  const [isOpen, setIsOpen] = React.useState(true);

  // Offset to show the dialog after last visit.
  const SHOW_MODAL_OFFSET = 1000 * 3600;
  const SHOW_MODAL_ON_CLOSE_OFFSET = 1000 * 3600;
  // Let's show 30 days later only
  const SHOW_MODAL_AFTER_REVIEW_CLICKED_OFFSET = 1000 * 30 * 24 * 3600;
  const STORAGE_KEY = 'last-visit-time';
  const now = new Date().getTime();

  let lastVisited = null;
  if (parseInt(window.localStorage.getItem(STORAGE_KEY), 10)) {
    lastVisited = parseInt(window.localStorage.getItem(STORAGE_KEY), 10);
  } else {
    window.localStorage.setItem(STORAGE_KEY, now.toString());
  }

  function closeLeaveReview() {
    setIsOpen(false);
    window.localStorage.setItem(STORAGE_KEY, (now - (SHOW_MODAL_OFFSET - SHOW_MODAL_ON_CLOSE_OFFSET)).toString());
  }

  function onLeaveReviewClick() {
    setIsOpen(false);
    window.localStorage.setItem(STORAGE_KEY, (now + SHOW_MODAL_AFTER_REVIEW_CLICKED_OFFSET).toString());
  }

  if (!lastVisited || (now - lastVisited < SHOW_MODAL_OFFSET)) {
    return null;
  }

  /* eslint max-len: 0 */
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeLeaveReview}
      className={classnames(GlobalCSS.modal, CSS.modalReviewWelcomeBg)}
      overlayClassName={classnames(GlobalCSS.overlay, CSS.modalReviewOverlay)}
    >
      <div className={GlobalCSS.modalLogo}>
        <span>
          HOOKAH
          <br />
          VALENCIA
        </span>
      </div>

      <div className={GlobalCSS.modalClose}>
        <span onClick={closeLeaveReview}>&#x2715;</span>
      </div>

      <div className={CSS.reviewInfoBlock}>
        <div className={CSS.reviewLogo}>
          <img src={LogoReviewsPNG} alt="Hookah Base" />
        </div>

        <div className={CSS.reviewTitle}>
          <FormattedMessage id="leaveReviewModalTitle" />
        </div>

        <div className={CSS.reviewDetails}>
          <FormattedMessage id="leaveReviewModalBody" />
        </div>

        <div>
          <span onClick={onLeaveReviewClick}>
            <Button
              to="https://g.page/r/CXuh2ibOnqUKEAg/review"
              target="_blank"
            >
              <FormattedMessage id="leaveReviewModalButton" />
            </Button>
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default LeaveReview;
