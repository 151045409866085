module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-4483b73e2e/0/cache/gatsby-plugin-google-gtag-npm-4.25.0-0abca1b5b1-61801a1c57.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NMHX4Q6H93"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-9e1ec85033/0/cache/gatsby-plugin-manifest-npm-4.25.0-25ef1b75df-a9c755d0e5.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"beeaf9fbe6a0d7f4bd86a3f5c4373615"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-mdx-virtual-5deaa0e6ea/0/cache/gatsby-plugin-mdx-npm-3.20.0-77a64e620c-cd9c68e250.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-prettier-build-npm-0.4.4-57bb747c7d-9db80cfeaa.zip/node_modules/gatsby-plugin-prettier-build/gatsby-browser.js'),
      options: {"plugins":[],"types":["html"],"concurrency":20,"verbose":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-800fc95340/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
