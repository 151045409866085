import { FormattedMessage } from 'react-intl';
import React from 'react';
import Button from './Button';

import * as CSS from './Cookie.module.css';

const STORAGE_KEY = 'cookies-accepted';

function Cookie() {
  const [cookieIsOpen, setCookieIsOpen] = React.useState(true);

  function closeCookie(e) {
    e.stopPropagation();
    e.preventDefault();

    setCookieIsOpen(false);

    window.localStorage.setItem(STORAGE_KEY, '1');
  }

  if (window.localStorage.getItem(STORAGE_KEY) === '1') {
    return null;
  }

  if (!cookieIsOpen) return null;

  return (
    <div
      className={CSS.modal}
    >
      <div className={CSS.title}><FormattedMessage id="cookiesTitle" /></div>
      <div>
        <FormattedMessage id="cookiesBody" />
      </div>

      <Button to="#" type="link" className={CSS.button} onClick={closeCookie}>
        <FormattedMessage
          id="cookiesAccept"
        />
      </Button>
    </div>
  );
}

export default Cookie;
