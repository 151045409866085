exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-menu-index-en-js": () => import("./../../../src/pages/menu/index.en.js" /* webpackChunkName: "component---src-pages-menu-index-en-js" */),
  "component---src-pages-menu-index-es-js": () => import("./../../../src/pages/menu/index.es.js" /* webpackChunkName: "component---src-pages-menu-index-es-js" */),
  "component---src-pages-menu-index-ru-js": () => import("./../../../src/pages/menu/index.ru.js" /* webpackChunkName: "component---src-pages-menu-index-ru-js" */),
  "component---src-pages-terms-index-en-js": () => import("./../../../src/pages/terms/index.en.js" /* webpackChunkName: "component---src-pages-terms-index-en-js" */),
  "component---src-pages-terms-index-es-js": () => import("./../../../src/pages/terms/index.es.js" /* webpackChunkName: "component---src-pages-terms-index-es-js" */),
  "component---src-pages-terms-index-ru-js": () => import("./../../../src/pages/terms/index.ru.js" /* webpackChunkName: "component---src-pages-terms-index-ru-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

