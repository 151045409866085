export default {
  weSpeak: 'Мы говорим по-русски / испански / английски',
  selectLanguage: 'Выберите язык',
  indexCoctailsShishas: 'Коктейли & Кальяны',
  reserve: 'Бронирование',
  viewMenu: 'Смотреть меню',
  menuTitle: 'Меню',
  menuHookah: 'Кальяная карта',
  menuDrinks: 'Барная карта',
  reserveTitle: 'Бронь',
  reservationRules: 'Бронирование столика осуществляется по предоплате 10 евро на человека',
  name: 'Имя',
  phone: 'Телефон',
  peopleCount: 'Количество персон',
  reservationDate: 'Дата резервации',
  reservationHour: 'Время',
  reservationTotal: 'Всего к оплате',
  reserveNow: 'Зарезервировать',
  reviewsTitle: 'Отзывы клиентов',
  leaveReview: 'Оставить отзыв',
  reviewsAllButton: 'Все отзывы',
  photosAndVideoTitle: 'Фото и Видео',
  informationAddressTitle: 'Как нас найти:',
  informationAddress: 'Информация и расположение',
  informationWorkingHours: 'Часы работы:',
  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  sunday: 'Воскресение',
  closed: 'закрыто',
  callUs: 'Позвонить нам',
  cookiesTitle: 'Этот вебсайт использует cookies.',
  cookiesAccept: 'Принять',
  cookiesBody: 'Мы испольуем cookies для улучшения вашего пользовательского опыта, персонализации содержания, '
      + 'и анализа нашего трафика. Нажимая "Принять", вы соглашаетесь на использование cookies.',
  leaveReviewModalTitle: 'Уже успели нас посетить?',
  leaveReviewModalBody: 'Мы будем очень благодарны, если вы поделитесь впечатлениями и оставите отзыв :-)',
  leaveReviewModalButton: 'Оставить отзыв',
  termsAndConditionsLabel: 'Правила и условия использования',
};
