// extracted by mini-css-extract-plugin
export var form = "LeaveReview-module--form--758c8";
export var formRow = "LeaveReview-module--form-row--eaeb5";
export var input = "LeaveReview-module--input--11a10";
export var label = "LeaveReview-module--label--d366f";
export var modalFinishTitle = "LeaveReview-module--modal-finish-title--9a429";
export var modalReviewFinishBg = "LeaveReview-module--modal-review-finish-bg--8e429";
export var modalReviewFormBg = "LeaveReview-module--modal-review-form-bg--0f420";
export var modalReviewOverlay = "LeaveReview-module--modal-review-overlay--b28dc";
export var modalReviewWelcomeBg = "LeaveReview-module--modal-review-welcome-bg--ac097";
export var reviewDetails = "LeaveReview-module--review-details--f1f96";
export var reviewInfoBlock = "LeaveReview-module--review-info-block--f89ef";
export var reviewLogo = "LeaveReview-module--review-logo--96fc5";
export var reviewStars = "LeaveReview-module--review-stars--89cd1";
export var reviewTitle = "LeaveReview-module--review-title--997a3";