export default {
  weSpeak: 'We speak Russian / Spanish / English',
  selectLanguage: 'Select your language',
  indexCoctailsShishas: 'Cocktails & Hookahs',
  reserve: 'Reservation',
  viewMenu: 'View Menu',
  menuTitle: 'Menu',
  menuHookah: 'Shishas',
  menuDrinks: 'Drinks & Cocktails',
  reserveTitle: 'Make a Reservation',
  name: 'Your Name',
  phone: 'Phone',
  peopleCount: 'Number of people',
  reservationDate: 'Reservation Date',
  reservationHour: 'Time',
  reservationTotal: 'Total to pay',
  reservationRules: 'Table reservation costs 10€ per person',
  reserveNow: 'Reserve Now',
  reviewsTitle: 'Our customers feedback',
  leaveReview: 'Leave a Review',
  reviewsAllButton: 'Show all',
  photosAndVideoTitle: 'Photos & Video',
  informationAddressTitle: 'Location:',
  informationAddress: 'Information & Location',
  informationWorkingHours: 'Working Hours:',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  closed: 'closed',
  callUs: 'Call Us',
  cookiesTitle: 'Our website uses cookies.',
  cookiesAccept: 'Accept',
  cookiesBody: 'We use cookies to enhance your browsing experience, serve personalized ads or content, '
      + 'and analyze our traffic. By clicking "Accept", you consent to our use of cookies.',
  leaveReviewModalTitle: 'Have you already visited us?',
  leaveReviewModalBody: 'We will be very grateful if you share your impressions and leave a review :-)',
  leaveReviewModalButton: 'Leave a Review',
  termsAndConditionsLabel: 'Terms & Conditions of use',
};
