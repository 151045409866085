export default {
  weSpeak: 'Hablamos Ruso / Español / Inglés',
  selectLanguage: 'Select your language in espanol',
  indexCoctailsShishas: 'Cocteles & Cachimbas',
  reserve: 'Reservar',
  viewMenu: 'Ver Carta',
  menuTitle: 'La Carta',
  menuHookah: 'Cachimbas',
  menuDrinks: 'Bebidas y Cócteles',
  reserveTitle: 'Reservar Mesa',
  reservationRules: 'Las reservas se realizan con pago anticipado de 10 euros por persona',
  name: 'Nombre',
  phone: 'Teléfono',
  peopleCount: 'Número de personas',
  reservationDate: 'Fecha de la reserva',
  reservationHour: 'Hora',
  reservationTotal: 'Total del pago anticipado',
  reserveNow: 'Reservar',
  reviewsTitle: 'Reseñas de nuestros clientes',
  leaveReview: 'Escribir una Reseña',
  reviewsAllButton: 'Todas las reseñas',
  photosAndVideoTitle: 'Fotos y Vídeos',
  informationAddressTitle: 'Dirección:',
  informationAddress: 'Informacion y Dirección',
  informationWorkingHours: 'Apetura:',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
  closed: 'cerrado',
  callUs: 'Llámame',
  cookiesTitle: 'Esta página web usa cookies',
  cookiesAccept: 'Aceptar',
  cookiesBody: 'Las cookies de este sitio web se usan para personalizar el contenido y los anuncios, '
        + 'ofrecer funciones de redes sociales y analizar el tráfico.',
  leaveReviewModalTitle: 'Ya has estado en nuestro local?',
  leaveReviewModalBody: 'Te agradeceríamos un montón si nos dejas un comentario compartiendo tus emociones :-)',
  leaveReviewModalButton: 'Dejar comentario',
  termsAndConditionsLabel: 'TÉRMINOS Y CONDICIONES DE USO',
};
