import React from 'react';

import { globalHistory } from '@reach/router';
import classnames from 'classnames';
import Link from 'gatsby-link';

import { getCurrentLangKey } from 'ptz-i18n';
import * as CSS from './Button.module.css';
import Languages from '../languages';
import 'intl';

function ExternalLink({ children, to, ...props }) {
  return <a href={to} {...props}>{children}</a>;
}

/* eslint no-param-reassign: 0 */
function Button({
  type, children, className, ...props
}) {
  if (props.to && props.to_fixed !== '1' && !props.to.startsWith('tel:') && !props.to.startsWith('https://')) {
    const { langs, defaultLangKey } = Languages;
    const langKey = getCurrentLangKey(langs, defaultLangKey, globalHistory.location.pathname);

    props.to = (langKey === defaultLangKey ? '' : `/${langKey}`) + props.to;
    props.to_fixed = '1';
  }

  let Component = Link;
  if (props.to && (props.to.startsWith('https://') || props.to.startsWith('tel:'))) {
    Component = ExternalLink;
  }

  return (
    <Component
      {...props}
      className={classnames(
        {
          [CSS.button]: !type || type === 'filled' || type === 'ghost',
        },
        [CSS[type || 'filled']],
        className,
      )}
    >
      {children}
    </Component>
  );
}

export default Button;
